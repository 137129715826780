<template>
	<div class="price-level-settings">
		<div class="price-level-settings__items" v-if="priceLevels.length > 0">
			<CollapseCard 
			v-for="price in priceLevels" 
			:key="`price_level_${price.id}`" 
			:title="price.name">

				<template v-slot:tools>
					<div @click="openEditHandler(price)" class="card-footer-item">Edit</div>
					<div @click="deleteHandler(price.id)" class="card-footer-item">Delete</div>
				</template>			

			</CollapseCard>
		</div>

		<div class="price-level-settings__items empty" v-else>
			No price levels
		</div>

		<div class="price-level-settings__add-form">
			<div class="add-form__content" v-if="createIsOpen || priceToEdit">
				<div class="add-form__content-field">
					<b-input v-model="addNewPriceLevel" ref="newPriceLevelName" :placeholder="`Enter price level name`"></b-input>
				</div>
				<div class="add-form__content-buttons">
					<b-button type="is-info" @click="submit">Save</b-button>
					<b-button type="is-info is-light" @click="closeCreateForm">Cancel</b-button>
				</div>
			</div>

			<div class="add-form__content-buttons" v-else>
				<b-button type="is-info" @click="openCreateForm" outlined expanded>Add</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import CMS from '../../service/cms/service';

export default {
	name: 'PriceLevelSettings',
	components: {
		CollapseCard: () => import('../inputs/CollapseCard.vue'),
	},	
	computed:{
		priceLevels(){
			return this.$store.getters.calendarPriceLevels
		},
	},
	data() { 
		return {
			priceToEdit: null,
			createIsOpen: false,
			addNewPriceLevel: "",
		};
	},

	methods: {

		openCreateForm(){
			this.createIsOpen = true
			this.$nextTick(() => {
				this.$refs.newPriceLevelName.focus()
			})
		},

		closeCreateForm(){
			this.createIsOpen = false
			this.addNewPriceLevel = ""
			this.priceToEdit = null
		},


		createHandler(){
			let calendarUid = this.$route.params.calendarUid
			let submitData = {
				name: this.addNewPriceLevel
			}
			CMS.prices.create(calendarUid, submitData).then( data => {
				if (data.err) {
					throw data.err
				}

				this.$store.dispatch('add-price-item', data)
				this.closeCreateForm()
			})
		},

		editHandler(){
			let calendarUid = this.$route.params.calendarUid
			let submitData = {
				name: this.addNewPriceLevel
			}
			CMS.prices.update(calendarUid, this.priceToEdit, submitData).then( data => {
				if (data.err) {
					throw data.err
				}

				this.$store.dispatch('upd-price-item', data)
				this.closeCreateForm()
			})
		},

		submit(){
			this.priceToEdit ? this.editHandler() : this.createHandler()
		},	

		deleteHandler(priceId){
			let calendarUid = this.$route.params.calendarUid
			CMS.prices.delete(calendarUid, priceId).then( data => {
				if (data.err) {
					throw data.err
				}

				this.$store.dispatch('del-price-item', priceId)
			})
		},

		openEditHandler(price){
			this.priceToEdit = price.id
			this.addNewPriceLevel = price.name
			this.$nextTick(() => {
				this.$refs.newPriceLevelName.focus()
			})
		},
	},
};
</script>

<style scoped>
.price-level-settings{
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.price-level-settings__items{
	display: flex;
	flex-direction: column;
	gap: 4px;
	animation: fade .3s;
}
.price-level-settings__items.empty{
	justify-content: center;
	height: 35px;
	align-items: center;
	color: grey;
}

.add-form__content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
  
.add-form__content-buttons{
	display: flex;
	flex-direction: row;
	gap: 16px;
	animation: fade .3s;
}

.add-form__content-field{
	animation: fade .3s;
}

.rating__content{
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	height: 20px;
	padding: 6px 0;
}
</style>